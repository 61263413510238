// src/App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './components/Homepage';

const App = () => {
  return (
    <Router>
      <div>
        {/* Navigation bar */}
  

        {/* Routes for different pages */}
        <Routes>

        <Route path="/" element={<Home />} />
        
        </Routes>
      </div>
    </Router>
  );
};

export default App;



