import React from 'react'
import Navbar from './invest/Navbar'
import Blog from './invest/Blog'
import Footer from './invest/footer'

function Homepage() {
  return (
    <>
    <div>
<Navbar />
<Blog />

<Footer />
    </div>
    </>
  )
}

export default Homepage