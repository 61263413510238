import React from 'react'

function Blog() {
  return (
    <div><div class="bg-gray-50 sm:px-6 px-4 py-12 font-sans">
    <div class="max-w-7xl mx-auto">
      <div class="text-left">
        <h2 class="text-3xl font-extrabold text-gray-800 inline-block">LATEST BLOGS</h2>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mt-16 max-md:max-w-lg mx-auto">
   
       
       
   
  <div class="cursor-pointer rounded overflow-hidden group">
    <div>
      <span class="text-sm block text-gray-400 mb-2">15 NOV 2023</span>
      <h3 class="text-xl font-bold text-gray-800 group-hover:text-blue-500 transition-all">
        Emerging Markets: Opportunities in 2024
      </h3>
      <div class="mt-4">
        <p class="text-gray-400 text-sm">
          Discover the most promising investment opportunities in emerging markets and how to capitalize on their growth potential.
        </p>
      </div>
    </div>
    <hr class="my-5 border-gray-300" />
    <div class="flex flex-wrap items-center gap-3">
      <img src="https://readymadeui.com/team-1.webp" class="w-9 h-9 rounded-full" />
      <p class="text-xs text-gray-400 font-semibold">BY JANE SMITH</p>
    </div>
  </div>

 
  <div class="cursor-pointer rounded overflow-hidden group">
    <div>
      <span class="text-sm block text-gray-400 mb-2">1 DEC 2023</span>
      <h3 class="text-xl font-bold text-gray-800 group-hover:text-blue-500 transition-all">
        ESG Investments: A Sustainable Future
      </h3>
      <div class="mt-4">
        <p class="text-gray-400 text-sm">
          Explore the rise of Environmental, Social, and Governance (ESG) investments and their impact on global portfolios.
        </p>
      </div>
    </div>
    <hr class="my-5 border-gray-300" />
    <div class="flex flex-wrap items-center gap-3">
      <img src="https://readymadeui.com/team-2.webp" class="w-9 h-9 rounded-full" />
      <p class="text-xs text-gray-400 font-semibold">BY DAVID LEE</p>
    </div>
  </div>

   
  <div class="cursor-pointer rounded overflow-hidden group">
    <div>
      <span class="text-sm block text-gray-400 mb-2">20 DEC 2023</span>
      <h3 class="text-xl font-bold text-gray-800 group-hover:text-blue-500 transition-all">
        The Future of Fintech in Global Markets
      </h3>
      <div class="mt-4">
        <p class="text-gray-400 text-sm">
          Uncover how fintech innovations are transforming investment strategies and enabling access to global markets.
        </p>
      </div>
    </div>
    <hr class="my-5 border-gray-300" />
    <div class="flex flex-wrap items-center gap-3">
      <img src="https://readymadeui.com/team-3.webp" class="w-9 h-9 rounded-full" />
      <p class="text-xs text-gray-400 font-semibold">BY EMILY CARTER</p>
    </div>
  </div>

 
  <div class="cursor-pointer rounded overflow-hidden group">
    <div>
      <span class="text-sm block text-gray-400 mb-2">5 JAN 2024</span>
      <h3 class="text-xl font-bold text-gray-800 group-hover:text-blue-500 transition-all">
        Real Estate Investing in 2024: What to Expect
      </h3>
      <div class="mt-4">
        <p class="text-gray-400 text-sm">
          A comprehensive guide to global real estate markets and emerging trends for the upcoming year.
        </p>
      </div>
    </div>
    <hr class="my-5 border-gray-300" />
    <div class="flex flex-wrap items-center gap-3">
      <img src="https://readymadeui.com/team-4.webp" class="w-9 h-9 rounded-full" />
      <p class="text-xs text-gray-400 font-semibold">BY SARAH ADAMS</p>
    </div>
  </div>

 
  <div class="cursor-pointer rounded overflow-hidden group">
    <div>
      <span class="text-sm block text-gray-400 mb-2">12 JAN 2024</span>
      <h3 class="text-xl font-bold text-gray-800 group-hover:text-blue-500 transition-all">
        Cryptocurrencies: Is It Still Worth Investing?
      </h3>
      <div class="mt-4">
        <p class="text-gray-400 text-sm">
          An analysis of the cryptocurrency market and its potential as a long-term investment.
        </p>
      </div>
    </div>
    <hr class="my-5 border-gray-300" />
    <div class="flex flex-wrap items-center gap-3">
      <img src="https://readymadeui.com/team-5.webp" class="w-9 h-9 rounded-full" />
      <p class="text-xs text-gray-400 font-semibold">BY MICHAEL WONG</p>
    </div>
  </div>

  
  <div class="cursor-pointer rounded overflow-hidden group">
    <div>
      <span class="text-sm block text-gray-400 mb-2">25 JAN 2024</span>
      <h3 class="text-xl font-bold text-gray-800 group-hover:text-blue-500 transition-all">
        Investing in AI: The Next Big Thing?
      </h3>
      <div class="mt-4">
        <p class="text-gray-400 text-sm">
          How artificial intelligence is reshaping global industries and creating new investment opportunities.
        </p>
      </div>
    </div>
    <hr class="my-5 border-gray-300" />
    <div class="flex flex-wrap items-center gap-3">
      <img src="https://readymadeui.com/team-6.webp" class="w-9 h-9 rounded-full" />
      <p class="text-xs text-gray-400 font-semibold">BY OLIVIA JONES</p>
    </div>
  </div>

   
</div>

    </div>
  </div>
  </div>
  )
}

export default Blog