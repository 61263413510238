import React from "react";

const Footer = () => {
  return (
    <footer className="bg-blue-900 text-white py-10">
      <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* About Section */}
        <div>
          <h3 className="text-2xl font-bold mb-4">About Us</h3>
          <p className="text-sm leading-6">
            Global Investment Hubs connects you to the world's top investment
            markets. Explore opportunities, insights, and tools to make
            informed financial decisions globally.
          </p>
        </div>

        {/* Quick Links */}
        <div>
          <h3 className="text-2xl font-bold mb-4">Quick Links</h3>
          <ul className="space-y-3">
            <li>
              <a
                href="#markets"
                className="hover:text-blue-300 transition-colors"
              >
                Markets
              </a>
            </li>
            <li>
              <a
                href="#insights"
                className="hover:text-blue-300 transition-colors"
              >
                Insights
              </a>
            </li>
            <li>
              <a
                href="#resources"
                className="hover:text-blue-300 transition-colors"
              >
                Resources
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="hover:text-blue-300 transition-colors"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>

        {/* Social Media */}
        <div>
          <h3 className="text-2xl font-bold mb-4">Follow Us</h3>
          <div className="flex space-x-4">
            <a
              href="#"
              className="hover:text-blue-300 transition-colors"
              aria-label="Facebook"
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M22 12a10 10 0 10-11.949 9.941v-7.036H8.692v-2.905h2.358v-2.211c0-2.34 1.384-3.629 3.506-3.629.987 0 2.018.175 2.018.175v2.218h-1.137c-1.121 0-1.47.697-1.47 1.411v1.791h2.502l-.4 2.905h-2.102v7.036A10.002 10.002 0 0022 12z" />
              </svg>
            </a>
            <a
              href="#"
              className="hover:text-blue-300 transition-colors"
              aria-label="Twitter"
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M19.633 7.581c.013.176.013.352.013.53 0 5.41-4.12 11.642-11.642 11.642-2.31 0-4.45-.676-6.248-1.835.33.039.643.052.986.052a8.27 8.27 0 005.115-1.759 4.134 4.134 0 01-3.86-2.867c.26.052.503.078.779.078.368 0 .736-.052 1.086-.13a4.122 4.122 0 01-3.31-4.043v-.052a4.138 4.138 0 001.863.526 4.129 4.129 0 01-1.835-3.435c0-.758.208-1.47.555-2.079a11.708 11.708 0 008.493 4.308c-.065-.303-.104-.62-.104-.948a4.13 4.13 0 014.13-4.13 4.094 4.094 0 012.999 1.3 8.22 8.22 0 002.608-.986 4.11 4.11 0 01-1.813 2.287 8.307 8.307 0 002.372-.646 8.765 8.765 0 01-2.06 2.13z" />
              </svg>
            </a>
            <a
              href="#"
              className="hover:text-blue-300 transition-colors"
              aria-label="LinkedIn"
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M4.98 3.5c0 1.365-1.114 2.48-2.48 2.48A2.485 2.485 0 010 3.5C0 2.135 1.115 1.02 2.48 1.02 3.867 1.02 4.98 2.135 4.98 3.5zM.48 6h4v15h-4V6zM8.98 6h3.867v2.07h.053a4.242 4.242 0 013.814-2.07c4.075 0 4.828 2.685 4.828 6.18V21h-4v-7.62c0-1.815-.033-4.155-2.5-4.155-2.502 0-2.89 1.954-2.89 4.024V21h-4V6z" />
              </svg>
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-blue-800 mt-8 pt-4 text-center">
        <p className="text-sm">
          © {new Date().getFullYear()} Global Investment Hubs. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
