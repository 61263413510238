import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto flex items-center justify-between px-4 py-3">
        {/* Logo */}
        <div className="flex items-center space-x-3">
          {/* <img
            src="https://via.placeholder.com/50" // Replace with your logo URL
            alt="Global Investment Hubs Logo"
            className="h-12 w-12 object-cover"
          /> */}
          <span className="text-xl font-bold text-gray-800">Investment Hubs</span>
        </div>

        {/* Hamburger Button */}
        <button
          className="md:hidden text-gray-800 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
            />
          </svg>
        </button>

        {/* Links */}
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } md:flex md:items-center md:space-x-6 absolute md:relative bg-white md:bg-transparent w-full md:w-auto top-14 md:top-auto left-0 md:left-auto z-50 shadow-md md:shadow-none`}
        >
          <a
            href="#home"
            className="block md:inline-block text-lg text-gray-800 hover:text-blue-500 py-2 px-4"
          >
            Home
          </a>
          <a
            href="#markets"
            className="block md:inline-block text-lg text-gray-800 hover:text-blue-500 py-2 px-4"
          >
            Markets
          </a>
          <a
            href="#insights"
            className="block md:inline-block text-lg text-gray-800 hover:text-blue-500 py-2 px-4"
          >
            Insights
          </a>
          <a
            href="#contact"
            className="block md:inline-block text-lg text-gray-800 hover:text-blue-500 py-2 px-4"
          >
            Contact
          </a>
          <a
            href="#about"
            className="block md:inline-block text-lg text-gray-800 hover:text-blue-500 py-2 px-4"
          >
            About Us
          </a>
        </div>

        {/* Button */}
        <div className="hidden md:block">
          <a
            href="#get-started"
            className="px-6 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg shadow-lg"
          >
            Get Started
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
